<template>
  <b-container>
  <b-row>
    <b-col class="info">
      <h2 class="heading">{{ $t('game.didyouknow') }}</h2>
      <b-card class="answer-description info">
        <img v-if="getCurrentInfo.image" :src="getCurrentInfo.image" class="title-image" />
        <p>
          {{ getCurrentInfo.text }}
        </p>
      </b-card>
      <div class="info-shadow"></div>
      <div class="info-shadow info-shadow-two"></div>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Info',
  computed: {
    ...mapGetters(['getCurrentInfo'])
  },
  beforeMount () {
    this.$store.dispatch('nextInfo')
  }
}
</script>
