<template>
  <b-container>
    <b-row>
       <b-col cols="12" class="text-center pt-5 pl-0 pr-0">
         <h2 class="heading mb-0">{{ $t('game.yourscore', { score: score + '/5' }) }}!</h2>
         <p class="px-4">{{ $t('game.bonus') }}</p>
         <canvas ref="my-canvas" width="320" height="320"></canvas>
       </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'yuki-createjs'
import { mapGetters } from 'vuex'
/* eslint-disable */
const preload = new createjs.LoadQueue()

export default {
  components: {},
  name: 'WheelOfFortune',
  data: function () {
    return {
      queue: null,
      stage: null,
      wheel: null,
      result: null,
      colors: ['1', '2', '3', '4', '5', '6', '7', '8']
    }
  },
  computed: {
    score () {
      return this.$store.state.current.correct
    },
    ...mapGetters(['getBonus'])
  },
  methods: {
    spin: function () {
      var r = this.wheel.rotation
      var sector = Math.random() * 360

      createjs.Tween.get(this.wheel, {loop:false})
        .to({rotation: r + 360 }, 1000, createjs.Ease.quadIn)
        .to({rotation: r + 4*360 + sector}, 4000, createjs.Ease.quadOut)
        .wait(500)
        .call(this.stopped)
        // soundEffect( "wheelspin" );
    },
    stopped: function () {
      var wheelrounds = Math.floor( this.wheel.rotation / 360 );
      var thesector = (this.wheel.rotation - wheelrounds*360) / (360/8);
      this.result = this.colors[ Math.ceil(thesector) - 1 ];
      if (this.result !== 4) {
        this.$emit('bonus' )
      }
      else{
        this.$emit('nobonus' )
      }
    },
    handleComplete: function (event) {
      this.wheel = new createjs.Bitmap(preload.getResult('wheel'))
      this.wheel.regX = this.wheel.getBounds().width / 2
      this.wheel.regY = this.wheel.getBounds().height / 2
      this.wheel.scaleX = this.wheel.scaleY = 0.6
      this.wheel.x = this.stage.canvas.width / 2
      this.wheel.y = this.stage.canvas.height / 2
      this.stage.addChild( this.wheel )

      var wheelpointer = new createjs.Bitmap(preload.getResult('wheelpointer'))
      wheelpointer.regX = wheelpointer.getBounds().width / 2
      wheelpointer.x = this.stage.canvas.width / 2
      wheelpointer.y = 0
      wheelpointer.scaleX = wheelpointer.scaleY = 0.6
      this.stage.addChild(wheelpointer)
      this.stage.update()
    }
  },
  beforeMount () {
    if (this.$store.state.current.correct < 4) {
      // TODO Add state info here
      console.debug('Low score')
      this.$store.commit('setPos','next')
    }
    if (this.getBonus) {
      // TODO Add state info here
      console.debug('Already bonus')
      this.$store.commit('setPos','next')
    }
  },
  mounted () {
    this.stage = new createjs.Stage(this.$refs['my-canvas'])

    createjs.Ticker.setFPS(60)
    createjs.Ticker.addEventListener('tick', this.stage)

    let assetList = [
      {src:  require('../assets/wheel.png'), id: "wheel"},
      {src:  require('../assets/wheelpointer.png'), id: "wheelpointer"}
    ]

    preload.on('complete', this.handleComplete, this);
    preload.loadManifest(assetList)
  }
}
</script>
