<template>
  <div class="page">
    <b-container class="align-self-start nav-padding">
      <h1 class="heading">{{ $t('general.main_text') }}</h1>
      <b-link class="btn btn-info" href="https://seastop.se">https://seastop.se</b-link>
    </b-container>
    <div class="logos">
      <img :src="require('../assets/EU.png')" width="90" />
      <img :src="require('../assets/CB_logo.png')" width="120" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'default',
  computed: {
  },
  methods: {
  }
}
</script>
