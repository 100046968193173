<template>
  <div :class="'page page-' + page">
    <Navigation page="page" />
    <b-container class="align-self-start nav-padding">
      <h1 class="heading pb-1">{{ getPage.title }}</h1>
      <div v-html="getPage.content"></div>
      <b-button variant="info" @click="$router.back()">{{ $t('game.back') }}</b-button>
    </b-container>
  </div>
</template>
<script>
import Navigation from '../components/Navigation'

export default {
  name: 'page',
  components: { Navigation },
  props: {
    page: String
  },
  computed: {
    getPage () {
      const page = this.$store.state.pages.find(page => page.slug === this.page)
      if (page && page[this.$store.state.language]) {
        return page[this.$store.state.language]
      }
      return ''
    }
  },
  methods: {
  }
}
</script>
