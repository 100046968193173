<template>
  <div class="page">
    <Navigation page='harbour' />
    <b-container class="align-self-start nav-padding">
      <b-row>
        <b-col v-if="getHarbour">
            <h1 class="heading">{{ $t('harbour.welcome', { name: getHarbour.title }) }}</h1>
            <p>
              {{ getHarbour.content }}
            </p>
            <b-button variant="info" v-if="valid" @click="playGame">{{ $t('harbour.play') }}</b-button>
            <b-link class="btn btn-info" href="https://seastop.se">{{ $t('harbour.to_page') }}</b-link>
            <p><b-link href="/page/privacy-policy">{{ $t('harbour.integrity') }}</b-link></p>
        </b-col>
      </b-row>
    </b-container>
    <div class="logos">
      <img :src="require('../assets/EU.png')" width="90" />
      <img :src="require('../assets/CB_logo.png')" width="120" />
    </div>
  </div>
</template>
<script>

import Navigation from '../components/Navigation'
import { mapGetters } from 'vuex'
import { findHarbour } from '../store'

export default {
  name: 'harbour',
  components: { Navigation },
  props: {
    path: String
  },
  data () {
    return {
      hash: window.location.hash.substr(1)
    }
  },
  computed: {
    language () {
      return this.$store.state.language
    },
    valid () {
      return this.hash === this.$store.getters.getHarbour.secret
    },
    ...mapGetters(['getHarbour'])
  },
  methods: {
    playGame () {
      if (this.valid) {
        this.$store.dispatch('startGame', { harbour: this.getHarbour.id, hash: this.hash })
        this.$router.push({ path: '/game' })
      } else {
        this.$store.commit('harbour', { harbour: '', hash: '' })
      }
    }
  },
  mounted () {
    if (this.$store.state.harbours.length && !findHarbour(this.$store.state.harbours, this.path)) {
      this.$router.push({ path: '/' })
    } else {
      this.$store.commit('harbour', { harbour: this.path, hash: '' })
    }
  }
}
</script>
