import axios from 'axios'
import store from './store'
import throttle from 'lodash/throttle'

const url = process.env.NODE_ENV === 'production' ? 'https://qr.seastop.se/wp-json/seastop/v1' : 'https://seastop.test/wp-json/seastop/v1'
const instance = axios.create({
  baseURL: url,
  timeout: 5000
})

const getCall = async (url, id = 'defaultCall') => {
  return new Promise((resolve, reject) => {
    store.commit('working', { id, start: true })
    instance.get(url)
      .then(response => {
        store.commit('working', { id, start: false })
        resolve(response)
      })
      .catch(reason => {
        store.commit('working', { id, start: false })
        reject(reason)
      })
  })
}

export const fetchQuestions = throttle((language) => {
  getCall('/questions/' + language, 'questions')
    .then(response => {
      store.commit('questions', { language, questions: response.data })
    })
}, 10000)

export const fetchInfo = throttle((language) => {
  getCall('/info/' + language, 'info')
    .then(response => {
      store.commit('info', { language, info: response.data })
    })
}, 10000)
export const fetchPages = throttle(() => {
  getCall('/pages', 'harbours')
    .then(response => {
      console.debug(response.data)
      store.commit('pages', { pages: response.data })
    })
}, 10000)
export const fetchHarbours = throttle(() => {
  getCall('/harbours', 'harbours')
    .then(response => {
      store.commit('harbours', { harbours: response.data })
    })
}, 10000)
