const fish = [
  {
    harbour: 'lappo',
    fish: 'abborre',
    latin: 'Perca fluviatilis'
  },
  {
    harbour: 'langvik_inre',
    fish: 'al',
    latin: 'Anguilla anguilla'
  },
  {
    harbour: 'nasslinge',
    fish: 'brax',
    latin: 'Abramis brama'
  },
  {
    harbour: 'bromarv',
    fish: 'flundra',
    latin: 'Platichthys flesus'
  },
  {
    harbour: 'peterzens',
    fish: 'gadda',
    latin: 'Esox lucius'
  },
  {
    harbour: 'fisko_gastbrygga',
    fish: 'gos',
    latin: 'Sander lucioperca'
  },
  {
    harbour: 'bomarsund',
    fish: 'hornsimpa',
    latin: 'Triglopsis quadricornis'
  },
  {
    harbour: 'sjokvarteret',
    fish: 'id',
    latin: 'Leuciscus idus'
  },
  {
    harbour: 'loka_hamn',
    fish: 'lake',
    latin: 'Lota lota'
  },
  {
    harbour: 'lokholmen',
    fish: 'lax',
    latin: 'Salmo Salar'
  },
  {
    harbour: 'ingmarso',
    fish: 'loja',
    latin: 'Alburnus alburnus'
  },
  {
    harbour: 'norra_stavsudda',
    fish: 'mort',
    latin: 'Rutilus rutilus'
  },
  {
    harbour: 'sandvik_kokar',
    fish: 'nors',
    latin: 'Osmerus eperlanus'
  },
  {
    harbour: 'kyrkviken_berg',
    fish: 'ruda',
    latin: 'Carassius carassius'
  },
  {
    harbour: 'husaro',
    fish: 'sarv',
    latin: 'Scardinius erythrophthalmus'
  },
  {
    harbour: 'degerby',
    fish: 'sik',
    latin: 'Coregonus lavaretus'
  },
  {
    harbour: 'haro',
    fish: 'skarpsill',
    latin: 'Clupea harengus'
  },
  {
    harbour: 'langvik_yttre',
    fish: 'smaspigg',
    latin: 'Pungitius pungitius'
  },
  {
    harbour: 'mojastrom',
    fish: 'stromming',
    latin: 'Clupea harengus'
  },
  {
    harbour: 'svartso',
    fish: 'sutare',
    latin: 'Tinca tinca'
  },
  {
    harbour: 'rodloga',
    fish: 'torsk',
    latin: 'Gadus morhua'
  }
]
export const getFish = (args) => {
  let returnFish
  if (args && args.harbour) {
    returnFish = fish.find(f => args.harbour === f.harbour)
  } else if (args && args.fish) {
    returnFish = fish.find(f => args.fish === f.fish)
  } else {
    return fish.map(f => {
      return f.fish
    })
  }
  if (returnFish) {
    return returnFish
  }
  return {
    harbour: '',
    fish: '',
    latin: ''
  }
}
