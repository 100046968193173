import Vue from 'vue'
import VueI18n from 'vue-i18n'
import BootstrapVue from 'bootstrap-vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { getLanguage } from './utils'
import { fetchHarbours, fetchInfo, fetchPages, fetchQuestions } from './backend'
import en from './languages/en'
import fi from './languages/fi'
import sv from './languages/sv'

import './scss/custom.scss'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-157796860-1' }
  },
  router)

// iOS Safari vh unit hack, uuh nasty
let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

const i18n = new VueI18n({
  locale: 'en', // set default locale
  messages: { 'en': en, 'fi': fi, 'sv': sv } // set locale messages
})

// You can use this to run functions on the store. These should be kept very light.
store.subscribe((mutation, state) => {
  // Transfer store.language to i18n module on store change
  if (mutation.type === 'language' || mutation.type === 'initialiseStore') {
    if (state.language) {
      i18n.locale = state.language
      document.documentElement.setAttribute('lang', state.language)
    }
  }

  // Get language data
  if (state.language && store.state.questions[store.state.language].length === 0) {
    fetchQuestions(store.state.language)
  }
  if (state.language && store.state.info[store.state.language].length === 0) {
    fetchInfo(store.state.language)
  }
  if (Object.keys(store.state.harbours).length === 0) {
    fetchHarbours()
  }
  if (Object.keys(store.state.pages).length === 0) {
    fetchPages()
  }
})

const init = () => {
  if (store.state.language || !store.state.language.length) {
    store.commit('language', { language: getLanguage() })
  }
}

new Vue({
  router,
  store,
  beforeCreate () {
    this.$store.commit('initialiseStore', { store: localStorage.getItem('store'), hmac: localStorage.getItem('hmac') })
  },
  i18n,
  render: h => h(App)
}).$mount('#app')

init()
