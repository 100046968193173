<template>
  <b-container class="harbour-bg" :style="getHarbour.style">
    <b-row>
      <b-col class="start">
        <div class="top text-white pb-4" >
          {{ $t('harbour.arrived_at') }}
          <h2 class="heading text-white pb-0">{{ getHarbour.title }}!</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="start">
        <div class="fish-card">
          <div class="header">
            ... {{ $t('harbour.catch') }}
            <h3 class="heading">{{ $t(fishName) }}</h3>
            ({{ fish.latin }})
            <img :src="fishSrc" class="fish-image"/>
            <br/> {{ $t('harbour.start_text') }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getFish } from '../data'
import { mapGetters } from 'vuex'

export default {
  name: 'start',
  components: {},
  computed: {
    fish () {
      return getFish({ harbour: this.$store.state.harbour.name })
    },
    fishSrc () {
      const fish = this.fish
      if (fish !== undefined) {
        return require('../assets/fish/' + fish.fish + '.svg')
      }
      return ''
    },
    fishName () {
      const fish = this.fish
      if (fish !== undefined) {
        return 'fish.' + fish.fish
      }
      return ''
    },
    ...mapGetters(['getHarbour'])
  },
  methods: {},
  mounted () {
    this.$store.commit('catchFish', { fish: this.fish.fish })
  }
}
</script>
