import Vue from 'vue'
import Vuex from 'vuex'
import { sha256 } from 'js-sha256'
import throttle from 'lodash/throttle'
import { getSecret } from '../utils'
import { getFish } from '../data'

Vue.use(Vuex)

// Changing the dbVersion wipes store from all clients. Only do this if you need to change the data structure.
const dbVersion = 6
const hmacKey = 'W7bNp8uBrhzQxSF2dCN74NzL'
const flow = [
  'start',
  'intro',
  'question',
  'info',
  'question',
  'info',
  'question',
  'info',
  'question',
  'info',
  'question',
  'info',
  'wheel',
  'end'
]

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    version: dbVersion,
    language: '',
    harbour: {
      name: '',
      time: 0,
      hash: ''
    },
    ui: {
      working: []
    },
    pages: [],
    questions: { en: [], sv: [], fi: [] },
    info: { en: [], sv: [], fi: [] },
    current: {
      position: 0,
      question: null,
      answer: -1,
      info: null,
      correct: 0
    },
    shown: {
      questions: [],
      info: []
    },
    harbours: [],
    fish: []
  },
  getters: {
    getQuestions (state) {
      if (!state.questions[state.language]) {
        return []
      }
      const questions = state.questions[state.language].map(question => {
        if ((question.harbours.length === 0 || question.harbours.indexOf(state.harbour.name) > -1) && state.shown.questions.indexOf(question.id) === -1) {
          return {
            ...question,
            answer: question.answer - question.id * 3 - 1
          }
        }
      }).filter(item => {
        return item !== undefined
      })
      if (questions.length) {
        return questions
      }
      return false
    },
    getCurrentQuestion (state) {
      if (!state.questions[state.language]) {
        return null
      }
      const questions = state.questions[state.language].filter(question => {
        return question.id === state.current.question
      })
      if (questions.length === 1) {
        return {
          ...questions[0],
          answer: questions[0].answer - questions[0].id * 3 - 1
        }
      }
      return null
    },
    getInfo (state) {
      if (!state.info[state.language]) {
        return []
      }
      return state.info[state.language].map(info => {
        if ((info.harbours.length === 0 || info.harbours.indexOf(state.harbour.name) > -1) && state.shown.info.indexOf(info.id) === -1) {
          return info
        }
      }).filter(item => {
        return item !== undefined
      })
    },
    getCurrentInfo (state) {
      if (!state.info[state.language]) {
        return null
      }
      const items = state.info[state.language].filter(item => {
        return item.id === state.current.info
      })
      if (items.length === 1) {
        return items[0]
      }
      return null
    },
    getHarbours (state) {
      return state.harbours.map(harbour => {
        return returnHarbour(harbour.id, state.harbours, state.language)
      })
    },
    getHarbour (state) {
      return returnHarbour(state.harbour.name, state.harbours, state.language)
    },
    getBonus (state, getter) {
      return getter.getHarbour.bonus
    },
    getWaterLevel (state) {
      let value = 'water'
      switch (flow[state.current.position]) {
        case 'start':
        case 'end':
        case 'intro':
        case 'wheel':
          value += ' mid'
          break
        case 'info':
          value += ' high'
          break
        case 'question':
          if (state.current.answer > -1) {
            value += ' high'
          } else {
            value += ' low'
          }
          break
        default:
          value += ' low'
          break
      }
      return value
    },
    getCorrect (state) {
      return state.current.correct
    },
    getPosition (state) {
      return flow[state.current.position]
    },
    isWorking (state) {
      return state.ui.working.length > 0
    },
    isStart (state) {
      return flow[state.current.position] === 'start'
    },
    isQuestion (state) {
      return flow[state.current.position] === 'question'
    },
    isInfo (state) {
      return flow[state.current.position] === 'info'
    },
    isIntro (state) {
      return flow[state.current.position] === 'intro'
    },
    isWheel (state) {
      return flow[state.current.position] === 'wheel'
    },
    isEnd (state) {
      return flow[state.current.position] === 'end'
    },
    showNext (state) {
      return state.current.position < flow.length && (flow[state.current.position] === 'intro' || flow[state.current.position] === 'info' || flow[state.current.position] === 'start' || (flow[state.current.position] === 'question' && state.current.answer > -1))
    }
  },
  mutations: {
    initialiseStore (state, payload) {
      const stateJson = payload.store
      // Check if the ID exists
      if (stateJson) {
        // Validate store
        if (sha256.hmac(hmacKey, stateJson) === payload.hmac) {
          const store = JSON.parse(stateJson)
          // Replace the state object with the stored item
          if (store.version === dbVersion) {
            console.log('Restore from localstorage')
            this.replaceState(
              Object.assign(state, store)
            )
          } else {
            console.warn('Version mismatch')
          }
        } else {
          console.warn('Invalid hmac')
        }
      }
    },
    language (state, payload) {
      state.language = payload.language
    },
    harbour (state, payload) {
      state.harbour = { name: payload.harbour, time: Date.now(), hash: payload.hash }
    },
    harbours (state, payload) {
      payload.harbours.map(harbour => {
        const h = findHarbour(state.harbours, harbour.id)
        if (h) {
          h.en = harbour.en
          h.sv = harbour.sv
          h.fi = harbour.fi
          h.image = harbour.image
        } else {
          state.harbours.push({
            ...harbour,
            bonus: ''
          })
        }
      })
    },
    questions (state, payload) {
      state.questions[payload.language] = payload.questions
    },
    info (state, payload) {
      state.info[payload.language] = payload.info
    },
    pages (state, payload) {
      state.pages = payload.pages
    },
    working (state, payload) {
      const check = state.ui.working.indexOf(payload.id)
      if (payload.start) {
        if (check === -1) {
          state.ui.working.push(payload.id)
        }
      } else {
        if (check !== -1) {
          state.ui.working.splice(check, 1)
        }
      }
    },
    setPos (state, payload) {
      switch (payload) {
        case 'next':
          state.current.position++
          break
        case 'prev':
          if (state.current.position > 0) {
            state.current.position--
          }
          break
        case 'start':
          state.current.position = 0
          break
        default:
          if (payload > -1 && payload < flow.length) {
            state.current.position = payload
          }
      }
    },
    setBonus (state, payload) {
      const harbour = findHarbour(state.harbours, state.harbour.name)
      harbour.bonus = payload.fish
    },
    setAnswer (state, payload) {
      state.current.answer = payload
    },
    markQuestion (state, payload) {
      state.shown.questions.push(payload.id)
      state.current.question = payload.id
      state.current.answer = -1
    },
    markInfo (state, payload) {
      state.shown.info.push(payload.id)
      state.current.info = payload.id
    },
    emptyShown (state, payload) {
      switch (payload) {
        case 'questions':
          state.shown.questions = []
          break
        case 'info':
          state.shown.info = []
          break
      }
    },
    incrementCorrect (state) {
      state.current.correct++
    },
    resetCorrect (state) {
      state.current.correct = 0
    },
    catchFish (state, payload) {
      if (typeof payload.fish === 'string' && payload.fish.length > 1 && state.fish.indexOf(payload.fish) === -1) {
        state.fish.push(payload.fish)
      }
    }
  },
  actions: {
    nextQuestion ({ commit, getters, state }) {
      if (!state.current.question || state.current.answer > -1 || getters.getCurrentQuestion === null) {
        let questions = getters.getQuestions
        while (questions === false) {
          commit('emptyShown', 'questions')
          questions = getters.getQuestions
        }
        const r = Math.floor(Math.random() * questions.length)
        const question = questions[r]
        commit('markQuestion', { id: question.id })
      }
    },
    nextInfo ({ commit, getters, state }) {
      let info = getters.getInfo
      while (info === false) {
        commit('emptyShown', 'info')
        info = getters.getInfo
      }
      const r = Math.floor(Math.random() * info.length)
      const inf = info[r]
      commit('markInfo', { id: inf.id })
    },
    awardBonusFish ({ commit, state, getters }) {
      const harbour = getters.getHarbour
      if (harbour && !harbour.bonus) {
        const fish = getFish().filter(f => {
          return state.fish.indexOf(f) === -1
        })
        if (fish.length) {
          const bonusFish = fish[Math.floor(Math.random() * fish.length)]
          commit('setBonus', { fish: bonusFish })
          commit('catchFish', { fish: bonusFish })
        }
      }
    },
    resetGame ({ commit }) {
      commit('resetCorrect')
      commit('setPos', 2)
    },
    startGame ({ commit }, payload) {
      commit('harbour', { harbour: payload.harbour, hash: payload.hash })
      commit('resetCorrect')
      commit('setPos', 'start')
    }
  },
  modules: {}
})

// Subscribe to store updates, update at most once every second.
store.subscribe(throttle((mutation, state) => {
  if (mutation.type !== 'initialiseStore') {
    const stateJson = JSON.stringify({ ...state, ui: undefined })
    const hmac = sha256.hmac(hmacKey, stateJson)
    // Store the state object as a JSON string
    localStorage.setItem('store', stateJson)
    localStorage.setItem('hmac', hmac)
  }
}, 1000))

export const findHarbour = (harbours, id) => {
  return harbours.find(h => h.id === id)
}

const returnHarbour = (id, harbours, language = 'en') => {
  const harbour = findHarbour(harbours, id)
  if (harbour && harbour[language]) {
    return {
      id: id,
      image: harbour.image,
      style: harbour.image ? 'background-image: url(' + harbour.image + ')' : '',
      title: harbour[language].title,
      content: harbour[language].content,
      bonus: harbour.bonus,
      secret: getSecret(id)
    }
  } else {
    return {
      id: '',
      image: '',
      style: '',
      title: '',
      content: '',
      bonus: '',
      secret: ''
    }
  }
}

export default store
