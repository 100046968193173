import * as sha256 from 'js-sha256'

export const getLanguage = (acceptedLanguages = ['en', 'sv', 'fi']) => {
  // This should work for most browsers.
  if (navigator.languages !== undefined) {
    for (let i = 0; i < navigator.languages.length; i++) {
      const l = navigator.languages[i]
      const la = l.substr(0, 2)
      if (acceptedLanguages.indexOf(la) > -1) {
        return la
      }
    }
  }
  // Fallback to OS language settings.
  const l = navigator.language || navigator.userLanguage
  const la = l.substr(0, 2)
  if (acceptedLanguages.indexOf(la) > -1) {
    return la
  }
  // If no match in accepted languages, return first accepted.
  return acceptedLanguages[0]
}
export const getSecret = (id) => {
  return sha256.sha256(id).substr(0, 6)
}
