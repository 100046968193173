<template>
  <div id="app" :class="getWaterLevel">
    <div class="working" v-if="isWorking">Working</div>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  computed: {
    ...mapGetters(['isWorking', 'getWaterLevel'])
  }
}
</script>
