<template>
  <b-nav :class="page">
    <li class="left">
      <router-link class="menu-item" to="/page/game-info" v-if="page === 'harbour'">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <circle cx="16" cy="16" r="14.5" fill="none" stroke="#0f112a" stroke-width="2.5"/>
          <rect width="4" height="13" rx="2" transform="translate(14 12)" fill="#0f112a"/>
          <rect width="4" height="4" rx="2" transform="translate(14 6)" fill="#0f112a"/>
        </svg>
        {{ $t('general.info') }}
      </router-link>
      <router-link class="menu-item" to="/corf" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <circle cx="16" cy="16" r="14.5" fill="none" stroke="#0f112a" stroke-width="2.5"/>
          <path
            d="M22.533,5.879a2.345,2.345,0,0,0-2.69.673c-.252.252-.589.084-.925,0-2.186-.673-3.868-2.186-5.633-3.615h0s3.027-.168.589-1.934A6.594,6.594,0,0,0,8.324.33h0A9.78,9.78,0,0,0,0,1.76,10.176,10.176,0,0,0,6.474,7.729l2.018.5c.589,1.093,1.6,2.354,2.859,2.186,1.093-.168.42-1.093-.5-1.85A52.392,52.392,0,0,0,18.5,8.486a.666.666,0,0,1,.673.42c.252.589.589,1.85,1.513,2.018,1.177.168.084-2.522.084-2.522S23.878,6.636,22.533,5.879ZM3.363,2.769A1.1,1.1,0,0,1,2.69,1.423,1.034,1.034,0,0,1,4.036.751,1.034,1.034,0,0,1,4.708,2.1,1.1,1.1,0,0,1,3.363,2.769Z"
            transform="translate(4.579 10.795)" fill="#0f112a"/>
        </svg>
        {{ $t('general.corf') }}
      </router-link>
    </li>
    <li class="right">
      <b-button variant="link" class="p-0" @click="start" v-if="page == 'end'">{{ $t('game.back') }}</b-button>
      <b-dropdown :text="getLanguage" variant="link" v-else>
        <b-dropdown-item href="#" v-for="lang in languages" :key="lang.value" @click="selectLanguage(lang.value)">
          {{ lang.text }}
        </b-dropdown-item>
      </b-dropdown>
    </li>
  </b-nav>
</template>

<script>
const languages = [
  { value: 'sv', text: 'Svenska' },
  { value: 'fi', text: 'Suomi' },
  { value: 'en', text: 'English' }
]

export default {
  name: 'navigation',
  props: {
    page: String
  },
  computed: {
    language () {
      return this.$store.state.language
    },
    languages () {
      return languages.filter(l => l.value !== this.language)
    },
    getLanguage () {
      const lang = languages.find(l => l.value === this.language)
      if (lang && lang.text) {
        return lang.text
      }
      return ''
    }
  },
  methods: {
    selectLanguage (language) {
      this.$store.commit('language', { language })
    },
    start () {
      this.$store.commit('setPos', 'start')
      // this.$router.push({ path: '/' })
    }
  }
}
</script>
