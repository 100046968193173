import Vue from 'vue'
import VueRouter from 'vue-router'
import Game from '../views/Game.vue'
import Harbour from '../views/Harbour'
import Secrets from '../views/Secrets'
import Default from '../views/Default'
import WheelOfFortune from '../components/WheelOfFortune.vue'
import Corf from '../views/Corf'
import Page from '../views/Page'

Vue.use(VueRouter)

const routes = [
  {
    path: '/game',
    name: 'game',
    component: Game
  },
  {
    path: '/wheel',
    name: 'wheel',
    component: WheelOfFortune
  },
  {
    path: '/secrets',
    name: 'secrets',
    component: Secrets
  },
  {
    path: '/corf',
    name: 'corf',
    component: Corf
  },
  {
    path: '/page/:page',
    name: 'page',
    component: Page,
    props: true
  },
  {
    path: '/:path',
    name: 'harbour',
    component: Harbour,
    props: true
  },
  {
    path: '*',
    name: 'default',
    component: Default
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
