<template>
  <b-container>
    <b-row v-if="score < 4">
      <b-col class="start pt-5">
        <h2 class="heading">{{ $t('game.yourscore', { score: score + '/5' }) }}</h2>
        <p class="px-4">{{ $t('game.tryagain') }}</p>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="start">
        <h2 class="heading">{{ $t('game.bonusfish') }}</h2>
        <div class="fish-card">
          <div class="header">
            <h3 class="heading">{{ $t(fishName) }}</h3>
            ({{ fish.latin }})
            <img :src="fishSrc" v-if="fish.fish" class="fish-image"/>
          </div>
          <router-link class="menu-item" to="/corf"><b-button variant="info" >{{ $t('general.showcorf') }}</b-button></router-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getFish } from '../data'
import { mapGetters } from 'vuex'

export default {
  name: 'end',
  components: {},
  computed: {
    fish () {
      return getFish({ fish: this.getBonus })
    },
    fishSrc () {
      const fish = this.fish
      if (fish !== undefined) {
        return require('../assets/fish/' + fish.fish + '.svg')
      }
      return ''
    },
    fishName () {
      if (this.fish !== undefined) {
        return 'fish.' + this.fish.fish
      }
      return ''
    },
    score () {
      return this.$store.state.current.correct
    },
    ...mapGetters(['getBonus'])
  }
}
</script>
