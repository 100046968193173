<template>
  <b-container class="question">
    <b-row>
      <b-col :class="'question question-' + question.type " v-if="currentAnswer === -1">
        <h2 class="heading">{{ question.title }}</h2>
        <img v-if="question.image" :src="question.image" class="title-image" />
        <b-container class="no-gutters">
          <b-row >
            <b-col :cols="question.type === 'image' ? 6 : 12" v-for="(option, index) in question.options" @click="checkAnswer(index)" :key="index" class="p-1" >
              <img v-if="question.type === 'image'" class="option-image" :src="option" alt="" />
              <button v-else class="btn btn-option">
                <span>{{ option }}</span>
              </button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col v-else>
        <h2 class="heading heading-answer">{{ currentAnswer === question.answer ? $t('game.right') : $t('game.wrong') }}</h2>
        <div v-if="question.image" class="image-frame-circle">
          <img :src="question.image" class="circle-image" />
        </div>
        <p class="mt-3 px-4">{{ currentAnswer === question.answer ? question.correct : question.incorrect }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Question',
  computed: {
    currentAnswer () {
      return this.$store.state.current.answer
    },
    question () {
      return this.$store.getters.getCurrentQuestion
    }
  },
  methods: {
    checkAnswer (index) {
      const correct = index === this.question.answer
      this.$emit('answer', { index, correct })
    }
  },
  beforeMount () {
    this.$store.dispatch('nextQuestion')
    // console.debug('current:' + this.currentAnswer)
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  button {
    display: block;
  }
</style>
