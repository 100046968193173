<template>
  <div class="corf">
    <div style="width: 100%; text-align: left;">
      <b-button variant="link" class="pt-3 px-4" @click="goBack">{{ $t('game.back') }}</b-button>
    </div>

    <h1 class="heading pb-0">{{ $t('general.corf') }}</h1>
    {{ $t('general.corfsaldo', { saldo: fish.length + '/21' }) }}
    <div class="net">
      <template v-for="(f, ind) in fish">
        <div class="fish" :key="f" :id="f">
          <img :src="getSrc(f)" :alt="f" :style="fishPos(ind)" @click="popFish(f)" />
        </div>
      </template>
    </div>
    <img class="corf-net" :src="require('../assets/seastop-sump.png')">
    <b-toast id="fish-toast" static>
      <h2 class="heading pb-0">{{$t('fish.' + selectedFish)}}</h2>
      ({{latin}})
      <img :src="getSrc(selectedFish)" />
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFish } from '../data'
export default {
  name: 'corf',
  components: { },
  data: function () {
    return {
      selectedFish: null
    }
  },
  computed: {
    latin () {
      return getFish({ fish: this.selectedFish }).latin
    },
    fish () {
      return this.$store.state.fish
    },
    ...mapGetters(['getHarbours'])
  },
  methods: {
    getSrc (fish) {
      if (fish) {
        return require('../assets/fish/' + fish + '.svg')
      }
      return ''
    },
    fishPos (ind) {
      if (ind % 2 === 0) {
        return 'right: 12%; top:' + (ind * 99) + 'px;'
      }
      return 'left: 12%; top:' + (ind * 99) + 'px;'
    },
    popFish (obj) {
      this.selectedFish = obj
      this.$bvToast.show('fish-toast')
    },
    unlock () {
      getFish().map(f => {
        console.debug(f)
        this.$store.commit('catchFish', { fish: f })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
