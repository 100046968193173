<template>
  <div class="page">
    <Navigation :page="getPosition" v-if="isStart || isIntro || isEnd"/>
    <div v-if="isStart" class="vignette"></div>
    <Start v-if="isStart" class="nav-padding" />
    <Intro v-if="isIntro" class="nav-padding" />
    <Question v-if="isQuestion" @answer="answer"/>
    <Info v-if="isInfo"/>
    <WheelOfFortune v-if="isWheel" @bonus="bonus" @nobonus="next" ref="wheel" />
    <End v-if="isEnd" class="nav-padding" />
    <b-button variant="start" @click="next" v-if="showNext">{{ isStart ? $t('game.start') : $t('game.continue') }}</b-button>
    <b-button variant="start" @click="$refs.wheel.spin()" v-if="isWheel" class="btn-menu mt-5 align-self-end" >{{ $t('game.spin') }}</b-button>
    <b-button variant="start" @click="restart"  v-if="isEnd">{{ $t('game.restart') }}</b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Start from '../components/Start'
import Question from '../components/Question'
import Info from '../components/Info'
import Intro from '../components/Intro'
import WheelOfFortune from '../components/WheelOfFortune'
import End from '../components/End'
import Navigation from '../components/Navigation'

export default {
  name: 'game',
  components: { Navigation, Start, Question, Info, Intro, WheelOfFortune, End },
  computed: {
    ...mapGetters(['getPosition', 'isStart', 'isQuestion', 'isInfo', 'isIntro', 'isWheel', 'isEnd', 'showNext'])
  },
  methods: {
    next () {
      this.$store.commit('setPos', 'next')
    },
    bonus () {
      this.$store.dispatch('awardBonusFish')
      this.next()
    },
    answer (value) {
      this.$store.commit('setAnswer', value.index)
      if (value.correct) {
        this.$store.commit('incrementCorrect')
      }
    },
    restart () {
      this.$store.dispatch('resetGame')
    }
  }
}
</script>
