<template>
  <div class="secrets">
    <div v-for="h in getHarbours" :key="h.id">
      <a :href="'/' + h.id + '#' + h.secret">https://qr.seastop.se/{{ h.id }}#{{ h.secret }}</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'secrets',
  components: { },
  computed: {
    ...mapGetters(['getHarbours'])
  },
  methods: {
  }
}
</script>
